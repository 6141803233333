import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Winter from './pages/Winter'
import Soumission from './pages/Soumission'
import AOS from 'aos'
import { useEffect } from 'react'

import 'aos/dist/aos.css'

const App = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Winter />} />
        <Route path='soumission' element={<Soumission />} />
        <Route path='*' element={<Winter />} />
      </Route>
    </Routes>
  )
}

export default App
