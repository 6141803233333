import Snowfall from "react-snowfall";
import { TbShovel, TbTruckDelivery, TbBuildingWarehouse } from "react-icons/tb";

import HeroBG from "../../assets/images/HeroBG.png";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <div
      id="home"
      className="w-full flex flex-col text-white font-medium bg-opacity-25"
      style={{
        background: `linear-gradient(to right, rgba(41, 54, 78, 0.8), rgba(41, 54, 78, 0.8)), url(${HeroBG})`,
      }}
    >
      <Snowfall style={{ opacity: 0.4 }} />
      <div
        className="flex flex-col gap-8 justify-center items-center w-full mt-20 md:mt-32 py-12 relative z-10"
        data-aos="fade-up"
      >
        <div className="flex flex-col items-center text-center gap-6">
          <h1 className="text-5xl w-[95%] md:w-2/3">
            Le déneigment n'a jamais été aussi{" "}
            <span className="text-orange-600">simple</span>.
          </h1>
          <h3 className="text-2xl font-light w-[95%] md:w-3/5 opacity-70">
            Un clic, quelques mots, et... c'est fait! <br /> Restez bien au
            chaud, on s'occupe de tout.
          </h3>
        </div>
        <button className="px-6 py-3 rounded-lg text-xl bg-orange-600 border-2 transition-all border-transparent  hover:bg-transparent hover:border-orange-600">
          Soumission en ligne
        </button>
        <div className="flex gap-8 justify-center flex-col w-[95%] md:flex-row md:w-[95%] xl:w-[70%] pt-24">
          <div className="flex-1 bg-slate-800 bg-opacity-80 rounded-lg p-8  box-border">
            <div className="flex w-full justify-between">
              <h1 className="text-2xl mb-2">Déneigement manuel</h1>
              <TbShovel className="text-3xl text-orange-600" />
            </div>
            <p className="opacity-50 pr-12 mb-6">
              Nous offrons un service de pelletage manuel qui saura convenir aux
              déneigements d’espaces restreints ou spécialisés selon vos
              demandes.
            </p>
            <Link
              to="/soumission"
              className={`pb-1  ${
                true && `border-b-2 border-b-orange-600`
              } box-border hover:text-orange-200 transition-all`}
            >
              Remplir une soumission
            </Link>
          </div>
          <div className="flex-1 bg-slate-800 bg-opacity-80 rounded-lg p-8  box-border">
            <div className="flex w-full justify-between">
              <h1 className="text-2xl mb-2">Déneigement de toitures</h1>
              <TbBuildingWarehouse className="text-3xl text-orange-600" />
            </div>
            <p className="opacity-50 pr-12 mb-6">
              Notre expertise nous permet de travailler sur différentes
              structures et nous respectons toutes les normes de sécurité
              nécessaires.
            </p>
            <Link
              to="/soumission"
              className={`pb-1  ${
                true && `border-b-2 border-b-orange-600`
              } box-border hover:text-orange-200 transition-all`}
            >
              Remplir une soumission
            </Link>
          </div>
          {/* <div className='flex-1 bg-slate-800 bg-opacity-80 rounded-lg p-8  box-border'>
            <div className='flex w-full justify-between'>
              <h1 className='text-2xl mb-2'>Transport de neige</h1>
              <TbTruckDelivery className='text-3xl text-orange-600' />
            </div>
            <p className='opacity-50 pr-12 mb-6'>
              Nous offrons les services de transport et de soufflage de neige
              vous permettant de récupérer l’espace encombré par la neige.
            </p>
            <a
              href=''
              className={`pb-1  ${
                true && `border-b-2 border-b-orange-600`
              } box-border hover:text-orange-200 transition-all`}
            >
              Remplir une soumission
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
