import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Footer from './Footer'
import Header from './Header'

const Layout = () => {
  const { pathname, hash } = useLocation()

  useEffect(() => {
    if (!hash) window.scrollTo(0, 0)
  }, [pathname, hash])

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}

export default Layout
