import Image from "../../assets/images/About.png";
import Wave from "../../assets/images/wave.svg";

const About = () => {
  return (
    <div
      id="about"
      className="flex flex-col w-full items-center bg-white pt-32 justify-center relative"
    >
      <div
        className="w-11/12 md:w-3/4 xl:w-1/2 pb-32 flex flex-col gap-8 items-center text-center"
        data-aos="fade-up"
      >
        <h1 className="font-medium text-5xl w-fit">
          Nous sommes C&C Général, une entreprise d'
          <span className="text-orange-600">ici</span>.
        </h1>
        <p className="text-lg">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Animi
          placeat minus adipisci asperiores voluptatem earum fugit. Eligendi eum
          praesentium, ducimus dicta vel quisquam dignissimos? Atque inventore
          hic sunt odio repudiandae.
        </p>
        <div
          className="w-full h-32 md:h-40 2xl:h-48 rounded-lg flex items-center px-8 justify-between"
          style={{
            background: `linear-gradient(to right, rgba(41, 54, 78, 0.8), rgba(41, 54, 78, 0.8)), url(${Image})`,
            backgroundPosition: "center",
          }}
        ></div>
        <p className="text-lg">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Animi
          placeat minus adipisci asperiores voluptatem earum fugit. Eligendi eum
          praesentium, ducimus dicta vel quisquam dignissimos? Atque inventore
          hic sunt odio repudiandae.
        </p>
      </div>
      {/* <img className='bottom-0' src={Wave} /> */}
    </div>
  );
};

export default About;
