import { useEffect, useState } from "react";
import { useFetcher, useLocation } from "react-router-dom";
import WhiteLogo from "../../assets/images/logoCC-Blanc.png";
import NavElements from "./NavElements";
import { TbMenu2 } from "react-icons/tb";

const Header = () => {
  const [offset, setOffset] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const baseBg = location.pathname.includes("soumission")
    ? "md:h-20 bg-slate-800 bg-opacity-95"
    : "md:h-32 bg-transparent";

  return (
    <div
      className={`${
        offset >= window.innerHeight * 0.1
          ? `bg-slate-800 bg-opacity-95 h-20`
          : `${baseBg} h-20 `
      } transform transition-all fixed top-0 w-full  text-white text-lg z-20 flex justify-between px-12`}
    >
      <img src={WhiteLogo} className="py-4" />
      <NavElements className="hidden md:flex" />
      <div
        className="flex md:hidden h-full items-center"
        onClick={() => setIsMenuOpen(true)}
      >
        <TbMenu2 className="text-3xl" />
      </div>
      <div
        onClick={() => setIsMenuOpen(false)}
        className={`${
          isMenuOpen ? "opacity-100 visible" : "opacity-0 invisible"
        } select-none transition-all fixed top-0 left-0 flex flex-col items-center py-20 gap-8 w-screen h-screen bg-slate-800 bg-opacity-95`}
      >
        <img src={WhiteLogo} className="w-1/4" />
        <NavElements />
      </div>
    </div>
  );
};

export default Header;
