import About from '../sections/Winter/About'
import Contact from '../sections/Winter/Contact'
import FAQ from '../sections/Winter/FAQ'
import Hero from '../sections/Winter/Hero'

const Winter = () => {
  return (
    <>
      <Hero />
      <About />
      <FAQ />
      <Contact />
    </>
  )
}

export default Winter
