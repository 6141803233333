import { TbPhone, TbUser } from 'react-icons/tb'
import { MdAlternateEmail } from 'react-icons/md'
import { BiMailSend } from 'react-icons/bi'
import { useForm } from 'react-hook-form'
import validator from 'validator'
import Input from '../../components/Shared/Input'
import TextArea from '../../components/Shared/TextArea'

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = (data) => console.log(data)

  return (
    <div
      id='contact'
      className='flex flex-col w-full items-center bg-white pt-32 justify-center relative'
    >
      <div
        className='w-11/12 md:w-3/4 xl:w-1/2 pb-32 flex flex-col gap-4 items-center text-center'
        data-aos='fade-up'
      >
        <h1 className='font-medium text-5xl w-fit'>
          Des questions? Contactez-nous
          <span className='text-orange-600'></span>.
        </h1>
        <p className=' mb-4 font-light opacity-70 w-3/4 md:w-3/5'>
          Écrivez-nous à l'aide du formulaire ci-dessous et nous vous répondrons
          dans les plus brefs délais.
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='w-full flex flex-col items-center gap-4 text-center'
        >
          <div className='flex w-full gap-4'>
            <Input
              className='flex-1'
              placeholder='Prénom'
              name='Prenom'
              register={{
                ...register('Prenom', {
                  required: true,
                  minLength: 2,
                }),
              }}
              error={errors['Prenom']}
              errorMessage={'Veuillez entrer un prenom valide.'}
            >
              <TbUser className='ml-4 text-2xl ' />
            </Input>
            <Input
              className='flex-1'
              placeholder='Nom'
              name='Nom'
              register={{
                ...register('Nom', {
                  required: true,
                  minLength: 2,
                }),
              }}
              error={errors['Nom']}
              errorMessage={'Veuillez entrer un nom valide.'}
            >
              <TbUser className='ml-4 text-2xl ' />
            </Input>
          </div>
          <Input
            className='w-full'
            placeholder='Courriel'
            name='Courriel'
            register={{
              ...register('Courriel', {
                required: true,
                validate: (val) => validator.isEmail(val),
              }),
            }}
            error={errors['Courriel']}
            errorMessage={'Veuillez entrer un courriel valide.'}
          >
            <MdAlternateEmail className='ml-4 text-2xl ' />
          </Input>
          <Input
            className='w-full'
            placeholder='Numéro de téléphone'
            name='Telephone'
            register={{
              ...register('Telephone', {
                required: true,
                validate: (val) => validator.isMobilePhone(val),
              }),
            }}
            error={errors['Telephone']}
            errorMessage={'Veuillez entrer un telephone valide.'}
          >
            <TbPhone className='ml-4 text-2xl ' />
          </Input>
          <TextArea
            className='w-full'
            placeholder='Décrivez votre demande'
            name='Description'
            register={{
              ...register('Description', {
                required: true,
                minLength: 50,
              }),
            }}
            error={errors['Description']}
            errorMessage={'Veuillez entrer un minimum de 50 caractères.'}
          />
          <button
            type='submit'
            className='flex gap-2 items-center text-white hover:text-black px-6 py-3 rounded-lg text-xl bg-orange-600 border-2 transition-all border-transparent  hover:bg-transparent hover:border-orange-600'
          >
            Envoyer <BiMailSend className='text-2xl' />
          </button>
        </form>
      </div>
    </div>
  )
}

export default Contact
