import { FiFacebook } from "react-icons/fi";
import { TbPhone } from "react-icons/tb";
import { MdAlternateEmail } from "react-icons/md";

import WhiteLogo from "../../assets/images/logoCC-Blanc.png";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <div
      id="footer"
      className="flex flex-col w-full items-center text-center md:text-left text-white relative"
    >
      <div className="flex flex-col gap-8 md:flex-row md:gap-0 w-full items-center bg-slate-700 p-12">
        <div className="flex flex-1 flex-col items-center md:items-start gap-4">
          <img src={WhiteLogo} className="w-32" />
          <p className="w-[20rem] font-light opacity-70">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia,
            nobis repudiandae?
          </p>
        </div>
        <div className="flex items-center flex-1 flex-col gap-4 font-medium">
          <HashLink to="/#home">Accueil</HashLink>
          <HashLink to="/#about">À propos</HashLink>
          <HashLink to="/#faq">FAQ</HashLink>
          <HashLink to="/#contact">Contactez-nous</HashLink>
          <HashLink to="/soumission">Remplir une soumission</HashLink>
        </div>
        <div className="flex items-end flex-1 flex-col gap-4">
          <p className="w-[20rem] opacity-70">Vous êtes prêts?</p>
          <HashLink
            to="/soumission"
            className="w-[20rem] flex gap-2 items-center text-white px-6 py-3 rounded-lg text-xl bg-orange-600 border-2 transition-all border-transparent  hover:bg-transparent hover:border-orange-600"
          >
            Soumission en ligne
          </HashLink>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:flex-row md:gap-0 w-full items-center justify-between relative bg-slate-800 p-12">
        <span className="opacity-70">
          © C&C Général inc. Tous droits réservés.
        </span>
        <span className="md:absolute top-1/2 left-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2">
          <span className="opacity-70">Confectionné avec amour par </span>
          <a
            href="https://2slash.io"
            target="_blank"
            className="text-orange-600 cursor-pointer"
          >
            2slash.io
          </a>
        </span>
        <div className="flex gap-4">
          <a
            href=""
            target="_blank"
            className="border border-white opacity-70 hover:opacity-100 hover:text-orange-600 hover:border-orange-600 transition-all cursor-pointer p-2 rounded-lg"
          >
            <FiFacebook className="text-2xl" />
          </a>
          <a
            href=""
            target="_blank"
            className="border border-white opacity-70 hover:opacity-100 hover:text-orange-600 hover:border-orange-600 transition-all cursor-pointer p-2 rounded-lg"
          >
            <TbPhone className="text-2xl" />
          </a>
          <a
            href=""
            target="_blank"
            className="border border-white opacity-70 hover:opacity-100 hover:text-orange-600 hover:border-orange-600 transition-all cursor-pointer p-2 rounded-lg"
          >
            <MdAlternateEmail className="text-2xl" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
