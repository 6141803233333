import { useState, useRef, useEffect } from 'react'
import { IoIosArrowDown } from 'react-icons/io'

const Question = ({ index, title, desc }) => {
  const [isOpened, setIsOpened] = useState(index == 0)

  const answerRef = useRef()

  useEffect(() => {
    answerRef.current.style.height = isOpened
      ? `${answerRef.current.scrollHeight}px`
      : '0px'
  }, [answerRef, isOpened])

  return (
    <div
      className='w-full transition-all bg-slate-800 bg-opacity-80 rounded-lg p-8 border border-orange-600 box-border cursor-pointer'
      onClick={() => setIsOpened((prev) => !prev)}
    >
      <div className='text-2xl flex gap-2 justify-between'>
        <div className='flex gap-2'>
          <span className='w-6 text-orange-600'>{index + 1}.</span>
          <h1>{title}</h1>
        </div>
        <IoIosArrowDown
          className={`${
            isOpened && `transform rotate-180`
          } text-4xl text-orange-600 transition-all`}
        />
      </div>
      <p
        ref={answerRef}
        className={`transition-all overflow-hidden opacity-50 pl-8 pr-8`}
      >
        {desc}
      </p>
    </div>
  )
}

export default Question
