import HeroBG from '../../assets/images/HeroBG.png'
import WhiteWaves from '../../assets/images/WhiteWaves.svg'

import Question from '../../components/FAQ/Question'

const FAQ = () => {
  return (
    <div
      id='faq'
      className='w-full text-white flex flex-col items-center pt-32'
      style={{
        background: `linear-gradient(to right, rgba(41, 54, 78, 0.8), rgba(41, 54, 78, 0.8)), url(${HeroBG})`,
      }}
    >
      <div
        className='w-11/12 md:w-3/4 xl:w-1/2 pb-32 flex flex-col gap-8 items-center'
        data-aos='fade-up'
      >
        <div className='flex flex-col gap-4 w-full items-center  text-center'>
          <h1 className='font-medium text-5xl w-fit'>Foire aux Questions</h1>
          <p className='opacity-70 font-light w-3/4 md:w-3/5'>
            Contactez-nous pour toute autre question, à l'aide de l'outil de
            clavardage dans le coin inférieur droit
          </p>
        </div>
        <div className='flex flex-col gap-4 w-full items-center'>
          {[...Array(8)].map((el, i) => (
            <Question
              key={i}
              index={i}
              title='À quelle heure passez-vous?'
              desc='Dès que 5 centimètres de neige a tombé et ce, avant 7h00 AM.'
            />
          ))}
        </div>
      </div>
      <img className='bottom-0' src={WhiteWaves} />
    </div>
  )
}

export default FAQ
