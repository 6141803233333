
import { HashLink } from 'react-router-hash-link'
import NavElement from './NavElement'
import Scrollspy from 'react-scrollspy'

const NavElements = ({className, closeMenu}) => {

    const navElements = [
        { title: 'Accueil', to: '/#home' },
        { title: 'À propos', to: '/#about' },
        { title: 'FAQ', to: '/#faq' },
        { title: 'Contactez-nous', to: '/#contact' },
    ]
    
    return (
        <div className={`${className} flex flex-col md:flex-row items-center gap-6`}>
        <Scrollspy
          className='flex flex-col md:flex-row items-center gap-6'
          items={['home', 'about', 'faq', 'contact']}
          currentClassName='border-b-orange-600'
          offset={-window.innerHeight / 2}
        >
          {navElements.map((el, i) => (
            <NavElement key={i} title={el.title} to={el.to} onClick={closeMenu} />
          ))}
        </Scrollspy>
        <HashLink
          onClick={closeMenu}    
          to='/soumission'
          className='py-2 px-4 rounded-xl bg-orange-600 border-2 transition-all border-transparent  hover:bg-transparent hover:border-orange-600  box-border'
        >
          Soumission en ligne
        </HashLink>
      </div>
    )
}

export default NavElements