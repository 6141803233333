const Input = ({
  className,
  name,
  children,
  placeholder,
  register,
  error,
  errorMessage,
}) => {
  const sharedInputClasses =
    'flex items-center rounded-lg border border-slate-800 transition-all focus-within:border-orange-600 hover:border-orange-600 border-opacity-50 box-border'

  return (
    <div className={`${className} flex flex-col`}>
      <div className={`${sharedInputClasses}`}>
        {children}
        <input
          {...register}
          className='p-4 w-full appearance-none bg-transparent outline-none'
          name={name}
          placeholder={placeholder}
        />
      </div>
      <span className='text-red-400 text-right'>{error && errorMessage}</span>
    </div>
  )
}

export default Input
