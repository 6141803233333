import { HashLink } from 'react-router-hash-link'

const NavElement = ({ title, to, className, onClick }) => {
  return (
    <HashLink
      onClick={onClick}
      to={to}
      className={`py-2 px-2 transition-all border-b-2 border-transparent box-border ${className}`}
    >
      {title}
    </HashLink>
  )
}

export default NavElement
