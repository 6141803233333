import { useEffect, useRef, useState } from "react";
import { BiMailSend } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { MdAlternateEmail } from "react-icons/md";
import {
  TbHome,
  TbPhone,
  TbUser,
  TbShovel,
  TbTruckDelivery,
  TbBuildingWarehouse,
} from "react-icons/tb";
import { useForm } from "react-hook-form";
import validator from "validator";
import Input from "../components/Shared/Input";
import { useDropzone } from "react-dropzone";
import TextArea from "../components/Shared/TextArea";

const Soumission = () => {
  const [services, setServices] = useState({
    manual: true,
    roofs: false,
    moving: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const [isPersonnalOpen, setIsPersonnalOpen] = useState(true);

  const personnalRef = useRef();

  const [isWorkOpen, setIsWorkOpen] = useState(true);

  const workRef = useRef();

  useEffect(() => {
    personnalRef.current.style.height = isPersonnalOpen
      ? `${personnalRef.current.scrollHeight}px`
      : "0px";
  }, [personnalRef, isPersonnalOpen]);

  useEffect(() => {
    workRef.current.style.height = isWorkOpen
      ? `${workRef.current.scrollHeight}px`
      : "0px";
  }, [workRef, isWorkOpen]);

  const sharedInputClasses =
    "flex items-center rounded-lg border border-slate-800 transition-all focus-within:border-orange-600 hover:border-orange-600 border-opacity-50 box-border";
  return (
    <div
      id="soumission"
      className="flex flex-col w-full items-center bg-white pt-32 pb-12 justify-center relative"
    >
      <div
        className="w-11/12 md:w-3/4 xl:w-1/2 flex flex-col gap-4 items-center text-center"
        data-aos="fade-up"
      >
        <h1 className="font-medium text-5xl w-fit">
          Soumission en ligne, dans le confort de chez{" "}
          <span className="text-orange-600">soi</span>.
        </h1>
        <p className=" mb-4 font-light opacity-70 w-3/4 md:w-3/5">
          Remplissez le formulaire ci-dessous afin que nous puissions évaluer et
          tailler une offre adapté pour vous.
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-4 items-center text-center"
        >
          <div className="flex flex-col gap-4 w-full">
            <div
              className="w-full text-left text-2xl flex justify-between cursor-pointer select-none"
              onClick={() => setIsPersonnalOpen((prev) => !prev)}
            >
              Informations Personnelles
              <IoIosArrowDown
                className={`${
                  isPersonnalOpen && `transform rotate-180`
                } text-4xl text-orange-600 transition-all`}
              />
            </div>
            <div
              className="flex flex-col w-full gap-4 transition-all overflow-hidden"
              ref={personnalRef}
            >
              <div className="flex w-full gap-4">
                <Input
                  className="flex-1"
                  placeholder="Prénom"
                  name="firstName"
                  register={{
                    ...register("firstName", {
                      required: true,
                      minLength: 2,
                    }),
                  }}
                  error={errors["firstName"]}
                  errorMessage={"Veuillez entrer un prenom valide."}
                >
                  <TbUser className="ml-4 text-2xl " />
                </Input>
                <Input
                  className="flex-1"
                  placeholder="Nom"
                  name="lastName"
                  register={{
                    ...register("lastName", {
                      required: true,
                      minLength: 2,
                    }),
                  }}
                  error={errors["lastName"]}
                  errorMessage={"Veuillez entrer un nom valide."}
                >
                  <TbUser className="ml-4 text-2xl " />
                </Input>
              </div>
              <Input
                className="w-full"
                placeholder="Courriel"
                name="email"
                register={{
                  ...register("email", {
                    required: true,
                    validate: (val) => validator.isEmail(val),
                  }),
                }}
                error={errors["email"]}
                errorMessage={"Veuillez entrer un courriel valide."}
              >
                <MdAlternateEmail className="ml-4 text-2xl " />
              </Input>
              <Input
                className="w-full"
                placeholder="Numéro de téléphone"
                name="phone"
                register={{
                  ...register("phone", {
                    required: true,
                    validate: (val) => validator.isMobilePhone(val),
                  }),
                }}
                error={errors["phone"]}
                errorMessage={"Veuillez entrer un telephone valide."}
              >
                <TbPhone className="ml-4 text-2xl " />
              </Input>
              <Input
                className="w-full"
                placeholder="Adresse personnelle"
                name="homeAddress"
                register={{
                  ...register("homeAddress", {
                    required: true,
                    minLength: 10,
                  }),
                }}
                error={errors["homeAddress"]}
                errorMessage={"Veuillez entrer une adresse valide."}
              >
                <TbHome className="ml-4 text-2xl " />
              </Input>
            </div>
            <div
              className="w-full text-left text-2xl flex justify-between cursor-pointer mt-4 select-none"
              onClick={() => setIsWorkOpen((prev) => !prev)}
            >
              Informations sur le lieu des travaux
              <IoIosArrowDown
                className={`${
                  isWorkOpen && `transform rotate-180`
                } text-4xl text-orange-600 transition-all`}
              />
            </div>
            <div
              className="flex flex-col w-full gap-4 transition-all overflow-hidden"
              ref={workRef}
            >
              <div className="flex flex-col md:flex-row gap-4">
                <div
                  className={`flex-1 cursor-pointer ${sharedInputClasses} ${
                    services.manual && " bg-orange-200 border border-orange-600"
                  }`}
                  onClick={() =>
                    setServices((prev) => ({ ...prev, manual: !prev.manual }))
                  }
                >
                  <TbShovel className="ml-4 text-2xl" />
                  <span className="p-4">Déneigement manuel</span>
                </div>
                <div
                  className={`flex-1 cursor-pointer ${sharedInputClasses} ${
                    services.roofs && " bg-orange-200 border border-orange-600"
                  }`}
                  onClick={() =>
                    setServices((prev) => ({ ...prev, roofs: !prev.roofs }))
                  }
                >
                  <TbBuildingWarehouse className="ml-4 text-2xl " />
                  <span className="p-4">Déneigement de toitures</span>
                </div>
                {/* <div
                  className={`flex-1 cursor-pointer ${sharedInputClasses} ${
                    services.moving && ' bg-orange-200 border border-orange-600'
                  }`}
                  onClick={() =>
                    setServices((prev) => ({ ...prev, moving: !prev.moving }))
                  }
                >
                  <TbTruckDelivery className='ml-4 text-2xl ' />
                  <span className='p-4'>Transport de neige</span>
                </div> */}
              </div>
              {!services.manual && !services.moving && !services.roofs && (
                <span className="text-red-400 text-right -mt-4">
                  Veuillez séléctionner au moins un service.
                </span>
              )}
              <Input
                className="w-full"
                placeholder="Adresse du lieu des travaux"
                name="workAddress"
                register={{
                  ...register("workAddress", {
                    required: true,
                    minLength: 10,
                  }),
                }}
                error={errors["workAddress"]}
                errorMessage={"Veuillez entrer une adresse valide."}
              >
                <TbHome className="ml-4 text-2xl " />
              </Input>
              <TextArea
                className="w-full"
                placeholder="Décrivez votre demande"
                name="description"
                register={{
                  ...register("description", {
                    required: true,
                    minLength: 50,
                  }),
                }}
                error={errors["description"]}
                errorMessage={"Veuillez entrer un minimum de 50 caractères."}
              />
              <div className="flex flex-col w-full gap-2">
                <div
                  {...getRootProps({
                    className: `w-full p-4 ${sharedInputClasses} border-dashed bg-orange-100 justify-center`,
                  })}
                >
                  <input
                    {...getInputProps()}
                    className="p-4 w-full appearance-none bg-transparent outline-none"
                  />
                  <p>
                    Faites glissez des photos ici, ou cliquez pour en
                    séléctionner
                  </p>
                </div>
                {acceptedFiles.map((file) => (
                  <li key={file.path} className="w-full text-left">
                    {file.path} - {file.size} bytes
                  </li>
                ))}
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="flex gap-2 items-center text-white hover:text-black px-6 py-3 rounded-lg text-xl bg-orange-600 border-2 transition-all border-transparent  hover:bg-transparent hover:border-orange-600"
          >
            Envoyer <BiMailSend className="text-2xl" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Soumission;
